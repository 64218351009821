import { useEffect, useState } from 'react';
import { getPriceWithCurrency } from '../../../utils/helpers';
import styles from './ProductSelector.module.sass';

function makePlural(str, quantity) {
  if (quantity === 1 || str.toLowerCase().endsWith('s')) {
    return str;
  } else {
    return `${str}s`;
  }
}

export default function ProductSelector({ offer, specialOffer, products, selectedProduct, onSelect }) {
  const someProductIsSubscription = products.some((item) => item.billingModelId !== '2');
  const [selectedOfferType, setSelectedOfferType] = useState('subscription');
  const filteredProducts = filterProducts(products);

  useEffect(() => {
    const anotherProductWithSameQuantity = filteredProducts.find(
      (item) => item.quantity === selectedProduct?.quantity && item.id !== selectedProduct?.id
    );

    if (anotherProductWithSameQuantity) {
      onSelect(anotherProductWithSameQuantity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOfferType]);

  function getSavings() {
    const qty = selectedProduct?.quantity;

    if (!qty) {
      return 0;
    }

    const oneTimeProduct = products.find((item) => item.billingModelId === '2' && item.quantity === qty);
    const subscriptionProduct = products.find((item) => item.billingModelId !== '2' && item.quantity === qty);

    return getPriceWithCurrency(
      oneTimeProduct.salePrice * 1 ||
        oneTimeProduct.price * 1 + oneTimeProduct.shippingPrice * 1 - subscriptionProduct.salePrice * 1 ||
        subscriptionProduct.price * 1,
      offer?.locale,
      offer?.currency
    );
  }

  function filterByOfferType(items) {
    if (someProductIsSubscription) {
      return items.filter((item) => {
        if (selectedOfferType === 'subscription') {
          return item.billingModelId !== '2';
        }

        return item.billingModelId === '2';
      });
    }

    return items;
  }

  function filterProducts(items) {
    return filterByOfferType(items);
  }

  return (
    <div className={styles.container}>
      {someProductIsSubscription && (
        <div className={styles.offerSelector}>
          <button
            className={`${styles.offerSelectorButton} ${
              selectedOfferType === 'one-time' && styles.offerSelectorButtonActive
            }`}
            onClick={() => setSelectedOfferType('one-time')}
          >
            One-Time Purchase
          </button>
          <button
            className={`${styles.offerSelectorButton} ${
              selectedOfferType === 'subscription' && styles.offerSelectorButtonActive
            }`}
            onClick={() => setSelectedOfferType('subscription')}
          >
            <p>
              Subscribe & <span>save extra {getSavings()}</span>
            </p>
            <b>+FREE SHIPPING ALWAYS</b>
          </button>
        </div>
      )}

      {/* Products */}
      <div className={styles.products}>
        {filteredProducts.map((product) => (
          <div
            key={product.id}
            className={`${styles.product} ${selectedProduct?.id === product.id && styles.productActive} ${
              product.soldOut === '1' && styles.productSoldOut
            }`}
            onClick={() => onSelect(product)}
          >
            {product.mostPopular && <div className={styles.productPopular}>Most Popular</div>}
            {product.bestValue && <div className={styles.productBestValue}>Best Value</div>}
            {product.soldOut === '1' && <div className={styles.productSoldOutBadge}>Sold Out</div>}
            <div className={styles.productImage}>
              <img src={product.image} alt={specialOffer.productName} />
            </div>
            <div className={styles.productContent}>
              <b>{product.quantity}x</b>
              {product.subtitle && <p className={styles.productSubtitle}>{product.subtitle}</p>}
              <p
                dangerouslySetInnerHTML={{
                  __html: makePlural(specialOffer.productName, product.quantity * 1),
                }}
              ></p>
              <s>{getPriceWithCurrency(product?.price, offer?.locale, offer?.currency)}</s>
              <span>{getPriceWithCurrency(product.salePrice, offer?.locale, offer?.currency)}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
