import axios from 'axios';

const url =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:6005'
    : 'https://manna-rx-api-jgusv.ondigitalocean.app';

export default axios.create({
  baseURL: url,
});
