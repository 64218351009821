import { useState } from 'react';
import { getPriceWithCurrency, mapColorsFromProducts } from '../../../../utils/helpers';
import ColorSelect from '../../../../components/ColorSelect/ColorSelect';
import steps from '../Default/img/steps.png';

import './ColorSelector.sass';

export default function ColorSelector({ order, specialOffer, onSubmit, offer }) {
  const mergedProductsData = specialOffer.productsData.map((product) => {
    const productDetail = specialOffer.productDetails[product.id];

    return {
      ...product,
      ...productDetail,
    };
  });

  const [selectedProduct, setSelectedProduct] = useState(mergedProductsData[0]);

  const [selectedProductColor, setSelectedProductColor] = useState({
    name: mergedProductsData[0].color,
    imageSrc: mergedProductsData[0].colorImage,
  });

  function handleColorSelect(color) {
    const selectedProduct = mergedProductsData.find((item) => item.color === color.name);

    setSelectedProductColor(color);
    setSelectedProduct(selectedProduct);
  }

  function renderProductSelector() {
    return (
      <select
        className='special-offer__select'
        name='productSelect'
        value={selectedProduct.id}
        onChange={(e) => setSelectedProduct(mergedProductsData.find((item) => item.id === e.target.value))}
      >
        {mergedProductsData.map((item, index) => {
          if (item.color === selectedProductColor.name) {
            return (
              <option key={index} value={item.id}>
                {item.quantity} {item.name} for{' '}
                {getPriceWithCurrency(
                  item.quantity > 1
                    ? item.salePrice || item.price / item.quantity
                    : item.salePrice || item.price,
                  offer?.locale,
                  offer?.currency
                )}
                {item.quantity > 1 && '/ea'}
              </option>
            );
          }

          return null;
        })}
      </select>
    );
  }

  return (
    <>
      <header className='page-header'>
        <div className='container'>
          <div className='page-header-inner'>
            <div className='page-header__hurry'>
              <b>HURRY!</b> Only 42 left in stock!
            </div>
            <div className='page-header__steps'>
              <img src={steps} alt='steps' />
            </div>
          </div>
        </div>
      </header>
      <div className='special-offer'>
        <div className='special-offer__title'>Wait, {order.firstName || 'dear customer'}!</div>
        <div
          className='special-offer__headline'
          dangerouslySetInnerHTML={{ __html: specialOffer.headline }}
        ></div>
        {specialOffer.subHeadline && (
          <div className='special-offer__subHeadline'>{specialOffer.subHeadline}</div>
        )}
        {specialOffer.gallery.map((item, index) => (
          <img key={index} src={item.source} alt='special offer' className='special-offer__img' />
        ))}
        <div className='special-offer__prices'>
          <div className='special-offer__prices--old'>
            <p>
              Retail Price:{' '}
              <b>{getPriceWithCurrency(selectedProduct?.price, offer?.locale, offer?.currency)}</b>
            </p>
          </div>
          <div className='special-offer__prices--new'>
            <p>
              Your Price:{' '}
              <b>
                {getPriceWithCurrency(
                  selectedProduct.quantity > 1
                    ? selectedProduct.salePrice || selectedProduct.price / selectedProduct.quantity
                    : selectedProduct.salePrice || selectedProduct.price,
                  offer?.locale,
                  offer?.currency
                )}
                {selectedProduct.quantity > 1 && '/ea'}
              </b>
            </p>
          </div>
        </div>
        {mergedProductsData.length > 1 && (
          <ColorSelect
            colors={mapColorsFromProducts(mergedProductsData)}
            selectedColor={selectedProductColor}
            onChange={handleColorSelect}
          />
        )}
        {mergedProductsData.length > 1 && renderProductSelector()}
        <div className='special-offer__buttons'>
          <button className='special-offer__buttons--add' onClick={() => onSubmit(selectedProduct)}>
            {specialOffer.ctaText
              ? specialOffer.ctaText
              : `Yes! Add ${selectedProduct.quantity} ${
                  specialOffer.productName
                } to my order for ${getPriceWithCurrency(
                  selectedProduct.quantity > 1
                    ? selectedProduct.salePrice || selectedProduct.price / selectedProduct.quantity
                    : selectedProduct.salePrice || selectedProduct.price,
                  offer?.locale,
                  offer?.currency
                )}
            ${selectedProduct.quantity > 1 ? '/ea' : ''}`}
          </button>
          <button className='special-offer__buttons--skip' onClick={() => onSubmit()}>
            No, I don't want it
          </button>
        </div>
      </div>
    </>
  );
}
