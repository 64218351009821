/* eslint-disable no-console */
import Cookies from 'js-cookie';
import api from '../api/api';
const CryptoJS = require('crypto-js');

function getFbcValue(query) {
  if (Cookies.get('_fbc')) {
    return Cookies.get('_fbc');
  }

  if (query.fbclid || Cookies.get('fbclid')) {
    const timestamp = new Date().getTime();
    return `fb.1.${timestamp}.${query.fbclid || Cookies.get('fbclid')}`;
  }

  return '';
}

function getHashedValue(string) {
  if (!string) return '';
  return CryptoJS.SHA256(string.toLowerCase()).toString(CryptoJS.enc.Hex);
}

export async function trackFacebookPageView(query) {
  const event = {
    event_name: 'PageView',
    event_time: Math.floor(Date.now() / 1000),
    user_data: {
      external_id: Cookies.get('mannaRx_ra_id'),
      client_user_agent: navigator.userAgent,
      fbc: getFbcValue(query),
      fbp: Cookies.get('_fbp'),
    },
    event_source_url: window.location.href,
    action_source: 'website',
    offerId: query.offerid.split('?')[0],
  };

  try {
    const config = {
      method: 'post',
      url: '/facebook/trackEvent',
      data: event,
    };

    const response = await api(config);
    return response.data;
  } catch (error) {
    console.log('FB error: ', error);
    return 'Facebook error: unable to track page view';
  }
}

export async function trackFacebookInitiateCheckout(offers, query) {
  if (typeof window['fbq'] !== 'undefined') {
    window.fbq('track', 'InitiateCheckout', {
      value: offers.reduce((acc, item) => acc + item.price * 1, 0),
      currency: 'USD',
      content_type: 'product',
      content_ids: offers.map((item) => item.product_id),
      external_id: Cookies.get('mannaRx_ra_id'),
    });
  }

  const event = {
    event_name: 'InitiateCheckout',
    event_time: Math.floor(Date.now() / 1000),
    user_data: {
      external_id: Cookies.get('mannaRx_ra_id'),
      client_user_agent: navigator.userAgent,
      fbc: getFbcValue(query),
      fbp: Cookies.get('_fbp'),
    },
    custom_data: {
      value: offers.reduce((acc, item) => acc + item.price * 1, 0),
      currency: 'USD',
      content_type: 'product',
      content_ids: offers.map((item) => item.product_id),
    },
    event_source_url: window.location.href,
    action_source: 'website',
    offerId: query.offerid.split('?')[0],
  };

  try {
    const config = {
      method: 'post',
      url: '/facebook/trackEvent',
      data: event,
    };

    const response = await api(config);
    return response.data;
  } catch (error) {
    console.log('FB error: ', error);
    return 'Facebook error: unable to track initiate checkout';
  }
}

export async function trackFacebookAddPaymentInfo(data, query) {
  const event = {
    event_name: 'AddPaymentInfo',
    event_time: Math.floor(Date.now() / 1000),
    user_data: {
      external_id: Cookies.get('mannaRx_ra_id'),
      client_user_agent: navigator.userAgent,
      fbc: getFbcValue(query),
      fbp: Cookies.get('_fbp'),
      em: getHashedValue(data.email),
      ph: getHashedValue(data.phone),
      ln: getHashedValue(data.lastName),
      fn: getHashedValue(data.firstName),
      country: getHashedValue(data.shippingCountry),
      st: getHashedValue(data.shippingState),
      zp: getHashedValue(data.shippingZip),
      ct: getHashedValue(data.shippingCity.replace(' ', '').toLowerCase()),
    },
    custom_data: {
      value: data.orderTotal,
      currency: 'USD',
      content_type: 'product',
      content_ids: data.offers.map((item) => item.product_id),
    },
    event_source_url: window.location.href,
    action_source: 'website',
    offerId: query.offerid.split('?')[0],
  };

  // Track the event using Facebook Pixel
  window?.fbq('track', event.event_name, {
    value: event.custom_data.value,
    currency: event.custom_data.currency,
    content_type: event.custom_data.content_type,
    content_ids: event.custom_data.content_ids,
    external_id: event.user_data.external_id,
    client_user_agent: event.user_data.client_user_agent,
    fbc: event.user_data.fbc,
    fbp: event.user_data.fbp,
    em: event.user_data.em,
    ph: event.user_data.ph,
    ln: event.user_data.ln,
    fn: event.user_data.fn,
    country: event.user_data.country,
    st: event.user_data.st,
    zp: event.user_data.zp,
    ct: event.user_data.ct,
    event_source_url: event.event_source_url,
    action_source: event.action_source,
    offerId: event.offerId,
  });

  try {
    const config = {
      method: 'post',
      url: '/facebook/trackEvent',
      data: event,
    };

    const response = await api(config);
    return response.data;
  } catch (error) {
    console.log('FB error: ', error);
    return 'Facebook error: unable to track payment info';
  }
}
